import React, { useState } from 'react';

interface InputProps {
  correctWord: string | undefined;
  guessAttempts: number;
  setCorrected: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  setGuessAttempts: (value: number | ((prevVar: number) => number)) => void;
}
export default function Input(props: InputProps): JSX.Element {
  const [input, setInput] = useState('');
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value.trim());
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && input == props.correctWord) {
      props.setCorrected(true);
    } else if (e.key === 'Enter' && input != props.correctWord) {
      props.setGuessAttempts(props.guessAttempts + 1);
    }
  };
  return (
    <input
      style={{
        width: '200px',
        height: '30px',
        fontWeight: 'bold',
        fontSize: '30px',
        textAlign: 'center',
      }}
      onKeyDown={(e) => {
        handleKeyDown(e);
      }}
      onChange={(e) => {
        handleChange(e);
      }}
      ref={(input) => {
        if (input != null) {
          input.focus();
        }
      }}
    ></input>
  );
}
