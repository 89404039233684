import React, { useEffect, useContext } from 'react';
import { InputContext } from '../Contexts/InputProvider';

// this wrapper will catch when the user hit enter
//if the user currently highlighted an element it will click that element
//allows the user to interact with the app without using the mouse
export default function EnterAsSelect(props: { children: React.ReactChild }): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { currEditingInput }: any = useContext(InputContext);
  useEffect(() => {
    if (currEditingInput.key === 'ENTER') {
      const currHighlightedElement = document?.activeElement as HTMLElement;
      currHighlightedElement?.click();
    }
    // eslint-disable-next-line
  }, [currEditingInput]);

  return <div>{props.children}</div>;
}
