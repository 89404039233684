import React, { useContext, useEffect } from 'react';
import { InputContext } from '../../Contexts/InputProvider';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(() => ({
  gameOver: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontWeight: 'bold',
    fontSize: '40px',
    background: '#f9f9f9',
  },
}));

interface GameOverProps {
  score: number;
  nameOfGame: string;
  averageReactionTime?: string;
}

export default function GameOver(props: GameOverProps): JSX.Element {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { currEditingInput }: any = useContext(InputContext);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    console.log(props.nameOfGame);
    const currHighScore = localStorage.getItem(`${props.nameOfGame}score`);
    if ((currHighScore && parseInt(currHighScore) < props.score) || currHighScore == null) {
      localStorage.setItem(`${props.nameOfGame}score`, props.score.toString());
    }
    if (currEditingInput.key === 'SPACE') {
      const parsed = QueryString.parse(location.search);
      if (parsed['start']) {
        history.go(0);
      } else {
        window.location.href = location.search + '?start=true';
      }
    } else if (currEditingInput.key === 'ESC') {
      history.push('/');
    }
    // eslint-disable-next-line
  }, [currEditingInput]);
  return (
    <Fade in={true} timeout={2000}>
      <div className={classes.gameOver}>
        <span>GAME OVER</span>
        <span style={{ display: 'flex', justifyContent: 'flex-start' }}>SCORE:</span>
        <span>{props.score}</span>
        <span style={{ display: 'flex', justifyContent: 'flex-start' }}>
          {props.averageReactionTime ? 'AVERAGE REACTION TIME:' : ''}
        </span>
        <span>{props.averageReactionTime ? props.averageReactionTime : ''}</span>
        <br></br>
        <span>Press Space to try again, or Esc to exit.</span>
      </div>
    </Fade>
  );
}
