import { Dispatch, SetStateAction, useEffect, useState } from 'react';

//input has to be an object , setState will update it even if it's the same value being pressed twice
interface CurrentInput {
  key: string;
}

enum Controls {
  Up = 'UP',
  Down = 'DOWN',
  Left = 'LEFT',
  Right = 'RIGHT',
}

enum EditingControls {
  Space = 'SPACE',
  Enter = 'ENTER',
  Backspace = 'BACKSPACE',
  Delete = 'DELETE',
  Escape = 'ESC',
}

interface CurrentInput {
  key: string;
}

export default function useInput(): {
  currInput: CurrentInput;
  inputPreference: { [key: string]: Controls } | null;
  setInputPreference: Dispatch<SetStateAction<{ [key: string]: Controls } | null>>;
  currEditingInput: CurrentInput;
  setCurrEditingInput: Dispatch<SetStateAction<CurrentInput>>;
  setEditingControls: Dispatch<SetStateAction<{ [key: string]: EditingControls }>>;
  setCurrInput: Dispatch<SetStateAction<CurrentInput>>;
} {
  const controls = {
    ' ': EditingControls.Space,
    Enter: EditingControls.Enter,
    Backspace: EditingControls.Backspace,
    Delete: EditingControls.Delete,
    Escape: EditingControls.Escape,
  };
  const [inputPreference, setInputPreference] = useState<{
    [key: string]: Controls;
  } | null>(null);
  const [editingControls, setEditingControls] = useState<{
    [key: string]: EditingControls;
  }>({ ...controls });
  const [currInput, setCurrInput] = useState<CurrentInput>({ key: '' });
  const [currEditingInput, setCurrEditingInput] = useState<CurrentInput>({
    key: '',
  });
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      //if the key pressed is in the accepted input controls, update the state
      if (inputPreference && inputPreference[event.key] != null) {
        setCurrInput({
          key: inputPreference[event.key],
        });
      } else if (event.shiftKey && event.key === 'Enter') {
        setCurrEditingInput({
          key: 'SHIFTENTER',
        });
      } else if (editingControls[event.key] != null) {
        setCurrEditingInput({ key: editingControls[event.key] });
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
    // eslint-disable-next-line
  }, [inputPreference]);

  return {
    currInput,
    inputPreference,
    setInputPreference,
    currEditingInput,
    setCurrEditingInput,
    setEditingControls,
    setCurrInput,
  };
}
