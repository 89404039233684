import React from 'react';

interface RectProps {
  color: string;
  text?: string;
}
export default function Rect(props: RectProps): JSX.Element {
  return (
    <div
      style={{
        fontSize: '30px',
        color: 'white',
        width: '40%',
        height: '40px',
        backgroundColor: props.color ? props.color : 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {props.text}
    </div>
  );
}
