import React, { useEffect, useState } from 'react';

export default function ReactionTime(): {
  setTriggerReaction: React.Dispatch<React.SetStateAction<boolean>>;
  getAverageTime: () => string | 0;
} {
  const [reactionTime, setReactionTime] = useState<number>(0);
  const [triggerReaction, setTriggerReaction] = useState<boolean>(false);
  const [reactionTimes, setReactionTimes] = useState<Array<number>>([]);
  useEffect(() => {
    if (triggerReaction) {
      const timeout = setInterval(() => {
        setReactionTime(reactionTime + 1);
      }, 1);
      return () => {
        clearTimeout(timeout);
      };
    } else if (reactionTime != 0) {
      setReactionTimes([...reactionTimes, reactionTime]);
      setReactionTime(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactionTime, triggerReaction]);

  const getAverageTime = () => {
    if (reactionTimes.length === 0) {
      return 0;
    }
    const total = reactionTimes.reduce((accumulator, time) => {
      return accumulator + time;
    });
    const ms = Math.floor(total / reactionTimes.length);
    return (ms / 1000).toFixed(2);
  };

  return { setTriggerReaction, getAverageTime };
}
