import React, { useEffect, useState } from 'react';
import Box from './Box';

interface RectPositioning {
  x: number;
  y: number;
}

interface CanvasProps {
  currPos: RectPositioning;
  livesRemaining: number;
  currDirection: string;
  hint: boolean;
}

interface RandomShapeIndices {
  [key: string]: boolean;
}

export default function Canvas(props: CanvasProps): JSX.Element {
  const [grid, setGrid] = useState<JSX.Element[][] | null>(null);
  const [currentValue, setCurrValue] = useState<RectPositioning>({ x: 1, y: 3 });

  useEffect(() => {
    if (grid != null) {
      const ammountOfShapes = Math.floor(Math.random() * 200) % 5;
      const points: RandomShapeIndices = {};
      const trailingBoxes: RandomShapeIndices = {};
      if (props.hint) {
        for (let i = 0; i < ammountOfShapes; i++) {
          const randomX = Math.floor(Math.random() * 4);
          const randomY = Math.floor(Math.random() * 5);
          points[`${randomX}${randomY}`] = true;
        }
        if (props.currDirection === 'UP' || props.currDirection === 'DOWN') {
          let startX = currentValue.x;
          const incOrDec = props.currDirection === 'UP' ? -1 : 1;
          while (startX != props.currPos.x) {
            trailingBoxes[`${startX}${currentValue.y}`] = true;
            startX = startX + incOrDec;
          }
        }
        if (props.currDirection == 'LEFT' || props.currDirection == 'RIGHT') {
          let startY = currentValue.y;
          const incOrDec = props.currDirection === 'LEFT' ? -1 : 1;
          while (startY != props.currPos.y) {
            trailingBoxes[`${currentValue.x}${startY}`] = true;
            startY = startY + incOrDec;
          }
        }
      }
      setGrid((prevGrid) => {
        if (prevGrid) {
          return prevGrid.map((box, x) => {
            return box.map((box, y) => {
              if (x == props.currPos.x && y == props.currPos.y) {
                return (
                  <Box
                    key="box"
                    index={`${x}, ${y}`}
                    rect={{
                      text: `${props.livesRemaining}`,
                      color: props.livesRemaining == 3 ? '#1E90FF' : props.livesRemaining == 2 ? '#f0e130' : 'red',
                    }}
                  />
                );
              } else if (trailingBoxes[`${x}${y}`] && props.hint === true) {
                return <Box key="box" index={`${x}, ${y}`} hint={true} direction={props.currDirection} />;
              } else if (points[`${x}${y}`]) {
                return <Box key="box" index={`${x}, ${y}`} random={true} />;
              } else {
                return <Box key="box" index={`${x}, ${y}`} />;
              }
            });
          });
        } else {
          return null;
        }
      });
      setCurrValue(props.currPos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currPos]);

  useEffect(() => {
    const temp = [];
    for (let i = 0; i < 4; i++) {
      const innerTemp = [];
      for (let j = 0; j < 5; j++) {
        if (i == currentValue.x && j == currentValue.y) {
          innerTemp.push(<Box index={`current`} rect={{ color: '#1E90FF' }} />);
        } else {
          innerTemp.push(<Box index={`${i}, ${j}`} />);
        }
      }
      temp.push(innerTemp);
    }
    setGrid(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
      }}
    >
      {grid}
    </div>
  );
}
