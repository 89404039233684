import React, { useEffect, useState } from 'react';
import Input from './Input';
import Timer from './Timer';
import './Styles/WordCross.css';
import TTSModule from './TTS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

enum Direction {
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

interface WordChoice {
  word: string;
  correction: string;
}

interface WordCrossChoice {
  wordChoice: WordChoice;
  direction: Direction;
}

interface CorrectionNeeded {
  word: string;
}

interface CurrentInput {
  key: string;
}

interface WordCrossProps {
  userSelected: CurrentInput;
  words: Array<WordCrossChoice>;
  correctDirection?: Direction;
  correctionNeeded?: CorrectionNeeded;
  correctOccurred: boolean;
  userInput: CurrentInput;
  TTS: TTSModule;
  setCurrEditingInput: (value: CurrentInput | ((prevVar: CurrentInput) => CurrentInput)) => void;
  setTimerUi: (value: JSX.Element | ((prevVar: JSX.Element) => JSX.Element)) => void;
  setAttemptingLast: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  noTimeLeft: boolean;
  score: number;
  startDirectionChoice: boolean;
  setScore: (value: number | ((prevVar: number) => number)) => void;
  setPause: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  v2?: boolean;
  inputPosition?: string;
}

interface DirectionMap {
  [key: string]: string;
}

interface Shake {
  id: number;
  shake: boolean;
}

export default function WordCross(props: WordCrossProps): JSX.Element {
  const [directionMap, setDirectionMap] = useState<DirectionMap>({
    LEFT: '',
    RIGHT: '',
    UP: '',
    DOWN: '',
  });
  const [renderInput, setRenderInput] = useState(false);
  const [corrected, setCorrected] = useState<boolean>(false);
  const [showHint, setShowHint] = useState<boolean>(false);
  const [guessAttempts, setGuessAttempts] = useState<number>(0);
  const [timeLimit] = useState(7000);
  const [shake, setShake] = useState<Shake>({ id: 0, shake: false });
  const [userSelected, setUserSelected] = useState<CurrentInput | null>(null);

  useEffect(() => {
    const newMap = directionMap;
    //map each word to it's position
    props.words.forEach((word) => {
      newMap[word.direction] = word.wordChoice.word;
    });
    setDirectionMap({ ...newMap });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.words]);

  useEffect(() => {
    //set timer for input of corrected word
    if (props.correctOccurred == true) {
      const timeout = setTimeout(() => {
        setShake({ id: shake.id + 1, shake: true });
        setRenderInput(false);
        setShowHint(false);
        props.setAttemptingLast(false);
        props.setPause(false);
        setGuessAttempts(0);
        setUserSelected(null);
      }, timeLimit);
      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.correctOccurred]);

  useEffect(() => {
    if (guessAttempts == 2) {
      props.setAttemptingLast(false);
      setShake({ id: shake.id + 1, shake: true });
      setRenderInput(false);
      props.setPause(false);
      setShowHint(false);
      setGuessAttempts(0);
      setUserSelected(null);
    } else if (guessAttempts > 0) {
      setShake({ id: shake.id + 1, shake: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guessAttempts]);

  useEffect(() => {
    //play second word and render the input box
    if (props.correctOccurred == true) {
      //console.log('text input audio')
      setShake({ id: shake.id + 1, shake: false });
      props.v2 ? void 0 : props.correctionNeeded ? props.TTS.speak(props.correctionNeeded.word) : void 0;
      setCorrected(false);
      props.setCurrEditingInput({ key: '' });
      //setRenderInput(true)
      setShowHint(true);
      props.setTimerUi(<Timer restart={true} totalSeconds={timeLimit / 1000} />);
    } else {
      setRenderInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.correctOccurred]);

  useEffect(() => {
    //when user hits space allow text to enter input
    if (props.v2 == true && showHint == true && props.userInput.key == 'ENTER' && props.inputPosition == 'RIGHT') {
      setRenderInput(true);
      props.setAttemptingLast(true);
      setShowHint(false);
    }
    if (props.v2 == true && showHint == true && props.userInput.key == 'SHIFTENTER' && props.inputPosition == 'LEFT') {
      setRenderInput(true);
      props.setAttemptingLast(true);
      setShowHint(false);
    }
    if (props.v2 != true && showHint == true && props.userInput.key == 'SPACE') {
      setRenderInput(true);
      props.setAttemptingLast(true);
      setShowHint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderInput, props.userInput]);

  useEffect(() => {
    //if successfully corrected resume the word selection process
    if (corrected == true) {
      //User got text correction right
      setTimeout(() => {
        console.log('corrected unset');
        setCorrected(false);
        props.setAttemptingLast(false);
        setShake({ id: shake.id + 1, shake: false });
        props.setScore(props.score + (guessAttempts == 1 ? 50 : 100));
        props.setPause(false);
        setRenderInput(false);
        setGuessAttempts(0);
        setUserSelected(null);
      }, 750);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corrected]);

  useEffect(() => {
    setUserSelected(null);
  }, [props.startDirectionChoice]);

  useEffect(() => {
    if (props.correctOccurred) {
    } else {
      setUserSelected(props.userSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userSelected]);

  return (
    <div
      key={shake.id}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <span className={showHint ? 'hint' : ''} style={{ display: showHint ? 'flex' : 'none' }}>
        Press
        {props.v2 != true
          ? ' SPACE To Replace Word'
          : props.inputPosition == 'LEFT'
          ? ' SHIFT+ENTER To Insert A Word Before'
          : ' ENTER To Insert A Word After'}
      </span>
      <br></br>
      <div
        style={{ height: '50vh', width: '40vw' }}
        className={corrected == true ? 'correctAnswer word' : shake.shake ? 'wordcross word' : 'word'}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: props.v2 != true ? 'column' : props.inputPosition == 'RIGHT' ? 'row' : 'row-reverse',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span
            className={
              userSelected?.key === 'UP' || (props.correctOccurred && props.correctDirection == 'UP') ? 'highlight' : ''
            }
            style={{
              color: guessAttempts !== 0 ? 'red' : renderInput && props.correctDirection == 'UP' ? '#1E90FF' : 'black',
            }}
          >
            {directionMap['UP']}
          </span>
          {renderInput && props.correctDirection == 'UP' ? (
            <Input
              guessAttempts={guessAttempts}
              setGuessAttempts={setGuessAttempts}
              setCorrected={setCorrected}
              correctWord={props.correctionNeeded?.word}
            />
          ) : (
            ''
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '33%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: props.v2 != true ? 'column' : props.inputPosition == 'RIGHT' ? 'row' : 'row-reverse',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              className={
                userSelected?.key === 'LEFT' || (props.correctOccurred && props.correctDirection == 'LEFT')
                  ? 'highlight'
                  : ''
              }
              style={{
                color:
                  guessAttempts !== 0 ? 'red' : renderInput && props.correctDirection == 'LEFT' ? '#1E90FF' : 'black',
              }}
            >
              {directionMap['LEFT']}
            </span>
            {renderInput && props.correctDirection == 'LEFT' ? (
              <Input
                guessAttempts={guessAttempts}
                setGuessAttempts={setGuessAttempts}
                setCorrected={setCorrected}
                correctWord={props.correctionNeeded?.word}
              />
            ) : (
              ''
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: props.v2 != true ? 'column' : props.inputPosition == 'RIGHT' ? 'row' : 'row-reverse',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              className={
                userSelected?.key === 'RIGHT' || (props.correctOccurred && props.correctDirection == 'RIGHT')
                  ? 'highlight'
                  : ''
              }
              style={{
                color:
                  guessAttempts !== 0 ? 'red' : renderInput && props.correctDirection == 'RIGHT' ? '#1E90FF' : 'black',
              }}
            >
              {directionMap['RIGHT']}
            </span>
            {renderInput && props.correctDirection == 'RIGHT' ? (
              <Input
                guessAttempts={guessAttempts}
                setGuessAttempts={setGuessAttempts}
                setCorrected={setCorrected}
                correctWord={props.correctionNeeded?.word}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: props.v2 != true ? 'column' : props.inputPosition == 'RIGHT' ? 'row' : 'row-reverse',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span
            className={
              userSelected?.key === 'DOWN' || (props.correctOccurred && props.correctDirection == 'DOWN')
                ? 'highlight'
                : ''
            }
            style={{
              color:
                guessAttempts !== 0 ? 'red' : renderInput && props.correctDirection == 'DOWN' ? '#1E90FF' : 'black',
            }}
          >
            {directionMap['DOWN']}
          </span>
          {renderInput && props.correctDirection == 'DOWN' ? (
            <Input
              guessAttempts={guessAttempts}
              setGuessAttempts={setGuessAttempts}
              setCorrected={setCorrected}
              correctWord={props.correctionNeeded?.word}
            />
          ) : (
            ''
          )}
        </div>
        <div style={{ height: '5vh' }}></div>
        <FontAwesomeIcon
          icon={duotone('circle-check')}
          size="2x"
          style={
            {
              '--fa-secondary-color': '#20FA65',
              '--fa-primary-color': 'black',
              '--fa-secondary-opacity': 1,
              '--fa-primary-opacity	': 1,
              visibility: corrected ? 'visible' : 'hidden',
              width: '100%',
            } as CSSProperties
          }
        />
      </div>
    </div>
  );
}
