import React, { useContext, useEffect, useState } from 'react';
import { TTSContext } from 'src/Components/Contexts/TTSProvider';
import Game from './Game';
import TTS from './TTS';
interface CurrentInput {
  key: string;
}

interface GameOver {
  isOver: boolean;
  finalScore: number;
}
interface GameProps {
  userInput: CurrentInput;
  userEditingInput: CurrentInput;
  setCurrEditingInput: (value: CurrentInput | ((prevVar: CurrentInput) => CurrentInput)) => void;
  backgroundImage?: string;
  setGameOver: (value: GameOver | ((prevVar: GameOver) => GameOver)) => void;
  tts?: SpeechSynthesisUtterance;
  v2?: boolean;
  start?: boolean;
}

export default function WhackAWord(props: GameProps): JSX.Element {
  const [start, setStart] = useState(props.start ? false : true);
  const [voiceModule, setVoiceModule] = useState<TTS | null>();
  const [end, setEnd] = useState<boolean>(false);
  // eslint-disable-next-line
  const { tts }: any = useContext(TTSContext);

  const handleStart = () => {
    setStart(true);
  };

  useEffect(() => {
    const loadModule = () => {
      const module = new TTS(tts.voice.name);
      module.init();
      setVoiceModule(module);
    };
    if (props.tts) {
      const module = new TTS(tts.voice.name);
      module.speechObj = props.tts;
      setVoiceModule(module);
    } else {
      loadModule();
    }
    // eslint-disable-next-line
  }, [props.tts]);

  if (end) {
    return <div></div>;
  }
  if (start != true) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <div
          onClick={() => {
            handleStart();
          }}
        >
          Start
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        {voiceModule ? (
          <Game
            v2={props.v2}
            TTS={voiceModule}
            setGameOver={props.setGameOver}
            userInput={props.userInput}
            setEnd={setEnd}
            setCurrEditingInput={props.setCurrEditingInput}
            userEditingInput={props.userEditingInput}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}
