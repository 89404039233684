import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import HowToPlay from '../../HowToPlay';
import WAWImage from '../../Pages/SelectGame/Thumbnails/Whack_a_Word_instructions.png';
import W2WImage from '../../Pages/SelectGame/Thumbnails/Whack_2_Words_instructions.png';
import WDImage from '../../Pages/SelectGame/Thumbnails/WanDirection_instructions.png';
import WTPImage from '../../Pages/SelectGame/Thumbnails/Whats_the_Point_instructions.png';

const useStyles = makeStyles(() => ({
  startScreen: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '40px',
    background: '#f9f9f9',
  },
  buttonRow: {
    display: 'flex',
    maxWidth: 'fit-content',
    fontWeight: 'bold',
    fontSize: '30px',
    flexDirection: 'column',
    inlineSize: 'fit-content',
    gap: '1em',
    textAlign: 'center',
  },
  button: {
    padding: '10px 10px',
    background: '#e0e0e0',
    color: '#4a4a4a',
    borderRadius: '8px',
    cursor: 'pointer',
    maxWidth: 'fit-content',
    alignSelf: 'center',
    display: 'inline-block',
  },
  title: {
    marginBottom: '20vh',
    marginTop: '20vh',
    color: '#4a4a4a',
    fontWeight: 'bold',
    fontSize: '40px',
  },
  backButton: {
    position: 'absolute',
    left: '10px',
    top: '20px',
    width: '200px',
    height: '20px',
    textAlign: 'center',
    fontSize: '25px',
    cursor: 'pointer',
  },
  description: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
}));

export interface CurrentInput {
  key: string;
}

interface StartProps {
  currInput: CurrentInput;
  setUserReady: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  nameOfGame: string;
  startGame?: boolean;
}

export default function Start(props: StartProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [currentTime, setCurrentTime] = useState<number>(3);
  const [initiate, setInitiate] = useState<boolean>(false);
  const [instructions, setInstructions] = useState<boolean>(false);

  const instructionDict: { [key: string]: JSX.Element } = {
    'Whack A Word': <img alt="instructions" height={'100%'} width={'100%'} src={WAWImage} />,
    Wandirection: <img alt="instructions" height={'100%'} width={'100%'} src={WDImage} />,
    "What's The Point?": <img alt="instructions" height={'100%'} width={'100%'} src={WTPImage} />,
    'Whack 2 Words': <img alt="instructions" height={'100%'} width={'100%'} src={W2WImage} />,
  };

  useEffect(() => {
    if (props.startGame) {
      setInitiate(true);
      props.setUserReady(true);
    }
    // eslint-disable-next-line
  }, [props.startGame]);

  useEffect(() => {
    if (initiate === true) {
      props.setUserReady(true);
      const timeout = setInterval(() => {
        setCurrentTime(currentTime - 1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [initiate, currentTime]);

  useEffect(() => {
    if (props.currInput.key === 'SPACE') {
      setInitiate(true);
      props.setUserReady(true);
    } else if (props.currInput.key === 'ENTER') {
    }
    // eslint-disable-next-line
  }, [props.currInput]);

  useEffect(() => {
    document.title = props.nameOfGame + ' | Hybrid Training';
    // eslint-disable-next-line
  }, []);

  if (initiate) {
    return (
      <div className={classes.startScreen}>
        <span
          className={classes.title}
          style={{
            padding: '10px 10px',
          }}
        >
          {initiate ? '' : 'Press SPACE to Begin Countdown'}
        </span>
        {currentTime > 0 ? currentTime : ''}
      </div>
    );
  } else {
    return (
      <div className={classes.startScreen}>
        <div
          tabIndex={1}
          className={classes.backButton}
          onClick={() => {
            history.push('/');
          }}
        >{`< Home`}</div>
        <span className={classes.title}>{props.nameOfGame}</span>
        <div className={classes.buttonRow}>
          <div>
            <div
              tabIndex={2}
              className={classes.button}
              onClick={() => {
                setInitiate(true);
              }}
            >
              Start Game
            </div>
            <p className={classes.description}>
              When you're ready, place your hands on the keyboard and press 'Space' to start.
            </p>
          </div>

          <div
            tabIndex={3}
            className={classes.button}
            onClick={() => {
              setInstructions(true);
            }}
          >
            How To Play
          </div>
        </div>
        {instructions === true ? (
          <HowToPlay
            title={'How To Play'}
            slides={[{ image: instructionDict[props.nameOfGame] }]}
            setClose={setInstructions}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}
