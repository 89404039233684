import './App.css';
import React from 'react';
import SelectGame from './Components/Pages/SelectGame';
import InsertGame from './Components/Games/InsertGame';
import Controls from './Components/Pages/Controls';
import InputProvider from './Components/Contexts/InputProvider';
import TTSProvider from './Components/Contexts/TTSProvider';
import ControlWrapper from './Components/ControlWrapper';
import ClickWrapper from './Components/ClickWrapper';
import EscapeWrapper from './Components/EscapeWrapper';
import AudioWarningWrapper from './Components/AudioWarningWrapper';
import MuteProvider from './Components/Contexts/MuteProvider';
import MuteWrapper from './Components/MuteWrapper';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

export default function App(): JSX.Element {
  return (
    <Router>
      <div>
        <Switch>
          <InputProvider>
            <TTSProvider>
              <MuteProvider>
                <ControlWrapper>
                  <ClickWrapper>
                    <EscapeWrapper>
                      <AudioWarningWrapper>
                        <MuteWrapper>
                          <Route exact path="/">
                            <SelectGame />
                          </Route>
                          <Route path="/wandirection">
                            <InsertGame nameOfGame={'wandirection'} />
                          </Route>
                          <Route path="/whatsthepoint">
                            <InsertGame nameOfGame={'whatsthepoint'} />
                          </Route>
                          <Route path="/whackaword">
                            <InsertGame nameOfGame={'whackaword'} />
                          </Route>
                          <Route path="/whackaword2">
                            <InsertGame nameOfGame={'whackaword2'} />
                          </Route>
                          <Route path="/controls">
                            <Controls />
                          </Route>
                        </MuteWrapper>
                      </AudioWarningWrapper>
                    </EscapeWrapper>
                  </ClickWrapper>
                </ControlWrapper>
              </MuteProvider>
            </TTSProvider>
          </InputProvider>
        </Switch>
      </div>
    </Router>
  );
}
