import React, { useEffect, useState } from 'react';

interface TimerProps {
  minutes: number;
}
export default function Timer(props: TimerProps): JSX.Element {
  const [totalSeconds, setTotalSeconds] = useState(props.minutes * 60);
  useEffect(() => {
    const timeout = setInterval(() => {
      setTotalSeconds(totalSeconds - 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [totalSeconds]);

  return (
    <div>
      {`${Math.floor(totalSeconds / 60)}:${totalSeconds % 60 < 10 ? '0' + (totalSeconds % 60) : totalSeconds % 60}`}
    </div>
  );
}
