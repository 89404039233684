import React, { useEffect, useState } from 'react';

export interface TimerProps {
  restart: boolean;
  totalSeconds: number;
}
export default function Timer(props: TimerProps): JSX.Element {
  const [currentTime, setCurrentTime] = useState(props.totalSeconds);
  useEffect(() => {
    const timeout = setInterval(() => {
      setCurrentTime(Math.round((currentTime - 0.1) * 10) / 10);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentTime]);

  useEffect(() => {
    if (props.restart == true) {
      setCurrentTime(props.totalSeconds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.restart]);

  return <div>{currentTime > 0 ? currentTime + 's' : '0s'}</div>;
}
