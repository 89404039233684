import { MuteContext } from '../Contexts/MuteProvider';
import { useContext } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

export default function MuteWrapper(props: {
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { mute, setMute }: any = useContext(MuteContext);

  const handleMute = (value: boolean) => {
    localStorage.setItem('mute', value === true ? 'true' : 'false');
    const updatedValue = { turnedOn: value, display: true };
    setMute((mute: { turnedOn: boolean; display: boolean }) => ({
      ...mute,
      ...updatedValue,
    }));
  };

  return (
    <div>
      {props.children}
      {mute.display ? (
        <div
          style={{ position: 'fixed', bottom: '0', left: '0', margin: '12px', cursor: 'pointer' }}
          onClick={() => {
            handleMute(!mute.turnedOn);
          }}
        >
          <FontAwesomeIcon
            style={{ color: '#616161' }}
            icon={!mute.turnedOn ? solid('volume') : solid('volume-slash')}
            size="3x"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
