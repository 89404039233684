import React, { useEffect, useContext } from 'react';
import { InputContext } from '../Contexts/InputProvider';
import { useHistory } from 'react-router-dom';

//this wrapper intercepts when the user hits the ESC key
//throught out the app if you hit escape you can be returned back to the home page
export default function EscapeWrapper(props: { children: React.ReactChild }): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { currEditingInput }: any = useContext(InputContext);
  const history = useHistory();
  useEffect(() => {
    if (currEditingInput.key === 'ESC') {
      history.push('/');
    }
    // eslint-disable-next-line
  }, [currEditingInput]);

  return <div>{props.children}</div>;
}
