import React from 'react';
import '../Styles/arrow.css';
import HollowArrow from './hollowArrow.svg';
import FilledArrow from './filledArrow.svg';
interface ArrowProps {
  orientation: string;
  highlight?: boolean;
}
export default function Arrow(props: ArrowProps): JSX.Element {
  return (
    <div className={`arrow ${props.orientation.toLowerCase()}  highlight-point`}>
      <img src={props.highlight ? FilledArrow : HollowArrow} />
    </div>
  );
}
