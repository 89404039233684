import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SlideShow from './SlideShow';

interface InstructionSlide {
  image: JSX.Element;
  description?: string;
}

interface HowToPlayProps {
  setClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  slides: Array<InstructionSlide>;
  title?: string;
}
export default function MaxWidthDialog(props: HowToPlayProps): JSX.Element {
  const handleClose = () => {
    props.setClose(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={true}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{props.title ? props.title : ''}</DialogTitle>
        <DialogContent>
          <SlideShow instructions={props.slides} />
        </DialogContent>
        <DialogActions>
          <Button tabIndex={3} onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
