import React from 'react';
import Rect from './Rect';
import Octagon from './Shapes/Octagon';
import Square from './Shapes/Square';
import VerticalRect from './Shapes/VerticalRect';
import Hint from './Shapes/Hint';
interface rect {
  color: string;
  text?: string;
}
interface Props {
  index?: string;
  rect?: rect;
  random?: boolean;
  hint?: boolean;
  direction?: string;
}
export default function Box(props: Props): JSX.Element {
  const randomShapes = [
    <Square key="square1" />,
    <Octagon key="octagon" />,
    <Square key="square2" />,
    <VerticalRect key="verticalRec" />,
  ];

  return (
    <div style={{ height: '19vh', width: '19vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {props.rect && <Rect text={props.rect.text ? props.rect.text : undefined} color={props.rect.color} />}
      {props.random && randomShapes[Math.floor(Math.random() * 4)]}
      {props.hint && <Hint direction={props.direction} />}
    </div>
  );
}
