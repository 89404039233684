import { useState } from 'react';

interface GameOverObj {
  isOver: boolean;
  finalScore: number;
  averageReactionTime?: string;
}
export default function GameOver(): {
  gameOver: GameOverObj;
  setGameOver: React.Dispatch<React.SetStateAction<GameOverObj>>;
} {
  const [gameOver, setGameOver] = useState<GameOverObj>({
    isOver: false,
    finalScore: 0,
  });

  return { gameOver, setGameOver };
}
