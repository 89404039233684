import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useMute(): {
  mute: { turnedOn: boolean; display: boolean };
  setMute: React.Dispatch<{ turnedOn: boolean; display: boolean }>;
} {
  const [mute, setMute] = useState<{ turnedOn: boolean; display: boolean }>({ turnedOn: false, display: true });
  const location = useLocation();

  useEffect(() => {
    const muteSetting = localStorage.getItem('mute');
    if (muteSetting) {
      let turnedOn;
      let display;
      if (muteSetting === 'true') {
        turnedOn = true;
      } else {
        turnedOn = false;
      }
      if (location.pathname.includes('whackaword')) {
        localStorage.setItem('mute', 'false');
        turnedOn = false;
        display = false;
      } else {
        display = true;
      }
      const updatedValue = { turnedOn: turnedOn, display: display };
      setMute((mute: { turnedOn: boolean; display: boolean }) => ({
        ...mute,
        ...updatedValue,
      }));
    }
  }, [location.pathname]);
  return { mute, setMute };
}
