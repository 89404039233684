import React from 'react';
import Arrow from '../../../Assets/Arrow';
import '../Styles/dpad.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'; // <--
import { CSSProperties } from 'react';
interface DPadProps {
  highlighted: string;
  wrong: number;
}

export default function DPad(props: DPadProps): JSX.Element {
  const getIcon = (wrongTimes: number) => {
    if (wrongTimes === 1) {
      return duotone('circle-1');
    } else if (wrongTimes === 2) {
      return duotone('circle-2');
    } else {
      return duotone('circle-3');
    }
  };

  const iconStyle = {
    '--fa-secondary-color': props.wrong == 1 ? '#1545BF' : props.wrong == 2 ? '#FFD600' : '#E32C29',
    '--fa-primary-color': 'black',
    '--fa-secondary-opacity': 1,
    '--fa-primary-opacity	': 1,
  };

  return (
    <div className="dpad">
      <div>
        <div>
          <Arrow orientation={'up'} highlight={props.highlighted == 'UP' ? true : false} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Arrow orientation={'left'} highlight={props.highlighted == 'LEFT' ? true : false} />
          <span>
            <FontAwesomeIcon style={iconStyle as CSSProperties} icon={getIcon(3 - (props.wrong - 1))} size="3x" />
          </span>
          <Arrow orientation={'right'} highlight={props.highlighted == 'RIGHT' ? true : false} />
        </div>
        <div>
          <Arrow orientation={'down'} highlight={props.highlighted == 'DOWN' ? true : false} />
        </div>
      </div>
    </div>
  );
}
