import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LeftControl from './navLeft.svg';
import RightControl from './navRight.svg';
import { LEFT_CONTROL_KEYS, RIGHT_CONTROL_KEYS } from 'src/Components/ControlWrapper';

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: '30px',
    height: '200px',
    width: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

interface ControlOptionProps {
  keyboard: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleChoice: Function;
  choice: string;
  tabIndex?: number;
}
export default function ControlOption(props: ControlOptionProps): JSX.Element {
  const classes = useStyles();
  const selectedControls = () => {
    if (props.choice === 'right') {
      return localStorage.getItem('controlPreference') === RIGHT_CONTROL_KEYS
        ? '3px solid rgb(30, 144, 255)'
        : '1px solid #bbb';
    } else {
      return localStorage.getItem('controlPreference') === LEFT_CONTROL_KEYS
        ? '3px solid rgb(30, 144, 255)'
        : '1px solid #bbb';
    }
  };
  return (
    <div
      tabIndex={props.tabIndex}
      onClick={() => {
        props.handleChoice(props.choice);
      }}
      className={classes.card}
      style={{
        border: selectedControls(),
      }}
    >
      <img src={props.choice === 'left' ? LeftControl : RightControl} alt={props.choice} />
    </div>
  );
}
