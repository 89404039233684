import React from 'react';
import '../../../../Assets/Styles/arrow.css';

interface HintProps {
  direction?: string;
}
export default function Hint(props: HintProps): JSX.Element {
  return (
    <div>
      <div className={`hint-arrow-${props.direction ? props.direction.toLocaleLowerCase() : ''}`}></div>
    </div>
  );
}
