import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  keyboardButton: {
    border: '1px solid #bbb',
    borderRadius: '5px',
    height: '50px',
    width: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
}));

interface KeyMapping {
  up: string;
  down: string;
  left: string;
  right: string;
  color: string;
}
export default function Keyboard(props: KeyMapping): JSX.Element {
  const classes = useStyles();
  return (
    <Grid container spacing={5} direction="row" justify="center" alignItems="center">
      <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12}>
        <div style={{ backgroundColor: props.color, color: 'white' }} className={classes.keyboardButton}>
          {props.up}
        </div>
      </Grid>
      <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={4}>
        <div style={{ backgroundColor: props.color, color: 'white' }} className={classes.keyboardButton}>
          {props.left}
        </div>
      </Grid>
      <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={4}>
        <div style={{ backgroundColor: props.color, color: 'white' }} className={classes.keyboardButton}>
          {props.down}
        </div>
      </Grid>
      <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={4}>
        <div style={{ backgroundColor: props.color, color: 'white' }} className={classes.keyboardButton}>
          {props.right}
        </div>
      </Grid>
    </Grid>
  );
}
