import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    height: '200px',
    cursor: 'pointer',
  },
  img: {
    height: '100%',
    width: '100%',
  },
}));

interface gameCardProps {
  ImageSource: string;
  GamePath: string;
  tabIndex: number;
}

export default function GameCard(props: gameCardProps): JSX.Element {
  const classes = useStyles();

  return (
    <div
      tabIndex={props.tabIndex}
      className={classes.card}
      onClick={() => {
        window.location.href = props.GamePath;
      }}
    >
      <img className={classes.img} alt="gamepreview" src={props.ImageSource}></img>
    </div>
  );
}
