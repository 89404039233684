import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import GameCard from './GameCard';
import WAW2Preview from './Thumbnails/Whack2Words_0323.svg';
import WAWPreview from './Thumbnails/WhackAWord_0323.svg';
import WD from './Thumbnails/WanDirection_0323.svg';
import WTPPreview from './Thumbnails/WhatsThePoint_0323.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fonts: {
    fontWeight: 'bold',
    fontSize: '30px',
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
  },
  editButton: {
    position: 'absolute',
    left: '10px',
    top: '20px',
    width: '400px',
    height: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  nameSpan: {
    marginTop: '.5em',
  },
}));

export default function SelectGame(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const handleChangeControls = () => {
    history.push('/controls');
  };
  const highScores = {
    wandirection: localStorage.getItem('wandirectionscore'),
    whatsthepoint: localStorage.getItem('whatsthepointscore'),
    whackaword: localStorage.getItem('whackawordscore'),
    whackaword2: localStorage.getItem('whackaword2score'),
  };

  const WHATS_THE_POINT = "What's The Point";
  const WAN_DIRECTION = 'WanDirection';
  const WHACK_A_WORD = 'Whack A Word';
  const WHACK_2_WORDS = 'Whack 2 Words';

  const trainingDescriptions = {
    [WHATS_THE_POINT]:
      'In this game, players need to identify the information on the screen and then select the correct directional key. What appears on the screen in What’s the Point are arrows that point in four directions. Players must select the directional key that indicates the direction/position of the arrow that is “highlighted”. For more information, select the game and refer to “How to Play”.',
    [WAN_DIRECTION]:
      'In this game, players need to focus on the movement of a shape on the screen while ignoring the other irrelevant shapes. A colored rectangle randomly moves up, down, left and right on the screen. Players must select the directional key that indicates the direction the rectangle moved. For more information, select the game and refer to “How to Play”.',
    [WHACK_A_WORD]:
      'In this game, there are words positioned at four points on the display – up, down, left and right. Audio will play of one of the displayed words, which players locate and select using the directional keys. When the correct word is selected, players must listen for a second spoken word and press the correct key to open a blank text field and type the second spoken word. For more information, select the game and refer to “How to Play”.',
    [WHACK_2_WORDS]:
      'In this game, there are words positioned at four points on the display – up, down, left and right. Audio will play that includes one of the displayed words, which player select the using the directional keys. When the correct word is selected, players press the correct key or key combination to enter the addition spoken word(s) either to complete the phrase. For more information, select the game and refer to “How to Play”.',
  };

  const [open, setOpen] = React.useState(false);
  const [training, setTraining] = React.useState({
    trainingName: '',
    trainingDesc: '',
  });

  const handleClickOpen = (name: string, desc: string) => {
    setTraining({
      trainingName: name,
      trainingDesc: desc,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.title = 'Select Game | Hybrid Training';
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
        <div
          tabIndex={1}
          className={classes.editButton}
          onClick={() => {
            handleChangeControls();
          }}
        >
          {'< Settings'}
        </div>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
          }}
          xs={12}
        >
          <h1>Interactive Trainings</h1>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20vh',
          }}
          xs={12}
        ></Grid>
        <Grid container style={{ rowGap: '2em' }}>
          <Grid item className={classes.item} xs={12} sm={6} md={6} lg={3}>
            <GameCard tabIndex={2} GamePath={'/whatsthepoint'} ImageSource={WTPPreview} />
            <span className={classes.nameSpan}>
              {WHATS_THE_POINT}{' '}
              <FontAwesomeIcon
                onClick={() => handleClickOpen(WHATS_THE_POINT, trainingDescriptions[WHATS_THE_POINT])}
                style={{ color: '#1E90FF', cursor: 'pointer' }}
                icon={solid('circle-info')}
              />
            </span>
            <span>High Score: {highScores['whatsthepoint'] ? highScores['whatsthepoint'] : 0}</span>
          </Grid>
          <Grid item className={classes.item} xs={12} sm={6} md={6} lg={3}>
            <GameCard tabIndex={3} GamePath={'/wandirection'} ImageSource={WD} />
            <span className={classes.nameSpan}>
              {WAN_DIRECTION}{' '}
              <FontAwesomeIcon
                onClick={() => handleClickOpen(WAN_DIRECTION, trainingDescriptions[WAN_DIRECTION])}
                style={{ color: '#1E90FF', cursor: 'pointer' }}
                icon={solid('circle-info')}
              />
            </span>
            <span>High Score: {highScores['wandirection'] ? highScores['wandirection'] : 0}</span>
          </Grid>
          <Grid item className={classes.item} xs={12} sm={6} md={6} lg={3}>
            <GameCard tabIndex={4} GamePath={'/whackaword'} ImageSource={WAWPreview} />
            <span className={classes.nameSpan}>
              {WHACK_A_WORD}{' '}
              <FontAwesomeIcon
                onClick={() => handleClickOpen(WHACK_A_WORD, trainingDescriptions[WHACK_A_WORD])}
                style={{ color: '#1E90FF', cursor: 'pointer' }}
                icon={solid('circle-info')}
              />
            </span>
            <span>High Score: {highScores['whackaword'] ? highScores['whackaword'] : 0}</span>
          </Grid>
          <Grid item className={classes.item} xs={12} sm={6} md={6} lg={3}>
            <GameCard tabIndex={5} GamePath={'/whackaword2'} ImageSource={WAW2Preview} />
            <span className={classes.nameSpan}>
              {WHACK_2_WORDS}{' '}
              <FontAwesomeIcon
                onClick={() => handleClickOpen(WHACK_2_WORDS, trainingDescriptions[WHACK_2_WORDS])}
                style={{ color: '#1E90FF', cursor: 'pointer' }}
                icon={solid('circle-info')}
              />
            </span>
            <span>High Score: {highScores['whackaword2'] ? highScores['whackaword2'] : 0}</span>
          </Grid>{' '}
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20vh',
          }}
          xs={12}
        ></Grid>
      </Grid>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">{training.trainingName}</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{training.trainingDesc}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
