import React, { useEffect, useState } from 'react';

interface MinuteTimerProps {
  continue: boolean;
}
export default function MinuteTimer(props: MinuteTimerProps): JSX.Element {
  const [totalSeconds, setTotalSeconds] = useState(0);
  useEffect(() => {
    const timeout = setInterval(() => {
      if (props.continue === true) {
        setTotalSeconds(totalSeconds + 1);
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [props.continue, totalSeconds]);

  return (
    <div>
      {`${Math.floor(totalSeconds / 60)}:${totalSeconds % 60 < 10 ? '0' + (totalSeconds % 60) : totalSeconds % 60}`}
    </div>
  );
}
