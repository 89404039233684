import { useEffect, useState } from 'react';

interface SpeechSynthesisProps {
  voice?: string;
}
export default function useSpeechSynthesis(props: SpeechSynthesisProps): {
  tts: SpeechSynthesisUtterance | undefined;
  setVoice: React.Dispatch<React.SetStateAction<string | undefined>>;
} {
  const [tts, setTTS] = useState<SpeechSynthesisUtterance | undefined>(undefined);
  const [voice, setVoice] = useState<string | undefined>(props.voice);

  const allVoicesObtained = new Promise<SpeechSynthesisVoice[]>(function (resolve) {
    let voices = window.speechSynthesis.getVoices();
    if (voices.length !== 0) {
      resolve(voices);
    } else {
      window.speechSynthesis.addEventListener('voiceschanged', function () {
        voices = window.speechSynthesis.getVoices();
        resolve(voices);
      });
    }
  });

  useEffect(() => {
    allVoicesObtained.then((voices) => {
      const speechObj = new SpeechSynthesisUtterance();
      const defaults = {
        volume: 1,
        rate: 0.75,
        pitch: 1,
        // lang: 'en-US',
      };
      Object.assign(speechObj, defaults);
      for (let i = 0; i < voices.length; i++) {
        if (voices[i].name === voice) {
          speechObj.voice = voices[i];
          setTTS(speechObj);
        }
      }
    });
    // eslint-disable-next-line
  }, [voice]);

  return { tts, setVoice };
}
