import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { InputContext } from '../../Contexts/InputProvider';

const useStyles = makeStyles(() => ({
  carousel: {
    height: '100%',
    width: '100%',
  },
  slide: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    border: '1px solid #bbb',
  },
}));

interface InstructionSlide {
  image: JSX.Element;
  description?: string;
}

interface InstructionsCarousel {
  instructions: Array<InstructionSlide>;
}

export default function SlideShow(props: InstructionsCarousel): JSX.Element {
  const classes = useStyles();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { currInput }: any = useContext(InputContext);

  useEffect(() => {
    if (currInput.key === 'LEFT') {
      handlePrevious();
    } else if (currInput.key === 'RIGHT') {
      handleNext();
    }
    // eslint-disable-next-line
  }, [currInput]);
  const handleNext = () => {
    if (currentSlide === props.instructions.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const handlePrevious = () => {
    if (currentSlide === 0) {
      if (props.instructions.length === 0) {
        return;
      }
      setCurrentSlide(props.instructions.length - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };
  return (
    <div className={classes.carousel}>
      <div className={classes.slide}>
        <div tabIndex={1}>
          {props.instructions.length > 1 ? (
            <ArrowBackIosIcon onClick={handlePrevious} style={{ cursor: 'pointer' }} />
          ) : (
            ''
          )}
        </div>
        <div className={classes.img}>{props.instructions[currentSlide].image}</div>
        <div tabIndex={2}>
          {props.instructions.length > 1 ? (
            <ArrowForwardIosIcon onClick={handleNext} style={{ cursor: 'pointer' }} />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
