import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { InputContext } from '../../Contexts/InputProvider';
import ControlOption from './ControlOption';
import Keyboard from './Keyboard';
import { useHistory, useLocation } from 'react-router-dom';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { TTSContext } from 'src/Components/Contexts/TTSProvider';
import TTS from 'src/Trainings/WhackAWord/TTS';
import { browserName } from 'react-device-detect';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '30px',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: '12px',
    padding: '10px',
    background: '#e0e0e0',
    color: '#4a4a4a',
    borderRadius: '8px',
    cursor: 'pointer',
    width: 'fit-content',
    position: 'fixed',
    right: '0',
    bottom: '0',
  },
  radio: {
    '&$checked': {
      color: 'rgb(30, 144, 255)',
    },
  },
  warning: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    background: '#ff4545',
    color: 'white',
    padding: '10px',
  },
  disabled: {
    opacity: '0.3',
  },
  checked: {},
}));

enum Controls {
  Up = 'UP',
  Down = 'DOWN',
  Left = 'LEFT',
  Right = 'RIGHT',
  Space = 'SPACE',
  Enter = 'ENTER',
  Backspace = 'BACKSPACE',
  Delete = 'DELETE',
}

const macVoiceWhitelist = new Set([
  'Samantha',
  'Daniel',
  'Eddy',
  'Flo',
  'Ralph',
  'Reed',
  'Sandy',
  'Shelley',
  'Google US English',
  'Google UK English Male',
]);

interface RedirectState {
  missingControls: boolean;
  missingVoice: boolean;
}

export default function SelectControls(): JSX.Element {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { currEditingInput, inputPreference, setInputPreference }: any = useContext(InputContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { tts, setVoice }: any = useContext(TTSContext);
  const [voiceName, setVoiceName] = useState<string>(tts == null ? '' : tts.voice.name);
  const location = useLocation<RedirectState>();

  const voices = (() => {
    let voices = window.speechSynthesis.getVoices();

    console.log(navigator.platform);

    if (browserName === 'Safari') {
      console.log('Filter the voices if the user is on Safari');
      voices = voices.filter((voice) => macVoiceWhitelist.has(voice.name));
    }

    if (navigator.platform.indexOf('Mac') != -1) {
      console.log('Filter the voices if the user is on Mac');
      voices = voices.filter((voice) => macVoiceWhitelist.has(voice.name));
      if (browserName === 'Safari' || browserName === 'Chrome') {
        console.log('Filter the voices if the user is on Safari or Chrome');
        voices = voices.filter((voice) => /en(-|_)(US)/.test(voice.lang));
      } else {
        voices = voices.filter((voice) => /en(-|_)(GB|US)/.test(voice.lang));
      }
    } else {
      voices = voices.filter((voice) => /en(-|_)(GB|US)/.test(voice.lang));
    }

    return voices;
  })();

  const history = useHistory();
  const handleChoice = (choice: string) => {
    if (choice === 'left') {
      setInputPreference({
        w: Controls.Up,
        s: Controls.Down,
        a: Controls.Left,
        d: Controls.Right,
      });
      localStorage.setItem(
        'controlPreference',
        JSON.stringify({
          w: Controls.Up,
          s: Controls.Down,
          a: Controls.Left,
          d: Controls.Right,
        }),
      );
    } else {
      setInputPreference({
        i: Controls.Up,
        k: Controls.Down,
        j: Controls.Left,
        l: Controls.Right,
      });
      localStorage.setItem(
        'controlPreference',
        JSON.stringify({
          i: Controls.Up,
          k: Controls.Down,
          j: Controls.Left,
          l: Controls.Right,
        }),
      );
    }
  };

  useEffect(() => {
    if (currEditingInput.key === 'ENTER') {
      const currHighlightedElement = document?.activeElement as HTMLElement;
      currHighlightedElement?.click();
    }
    // eslint-disable-next-line
  }, [currEditingInput]);

  useEffect(() => {
    document.title = 'Controls | Hybrid Training';
  }, []);

  // eslint-disable-next-line
  const handleVoiceChange = (ev: any) => {
    setVoiceName(ev.target.value);
    localStorage.setItem('voicePreference', ev.target.value);
    const module = new TTS(ev.target.value);
    const voice = voices.filter((voice) => {
      if (ev.target.value == voice.name) {
        return voice;
      }
    });

    const obj = {
      volume: 1,
      rate: 0.75,
      pitch: 1,
      voice: voice[0],
    };
    Object.assign(module.speechObj, obj);
    module.speak('How are you');
  };

  useEffect(() => {
    if (tts != null) {
      setVoiceName(tts.voice.name);
    }
    // eslint-disable-next-line
  }, [tts]);

  useEffect(() => {
    const newVoice = voices.filter((voice) => voice.name == voiceName);
    if (newVoice && newVoice.length >= 1 && newVoice[0]) {
      setVoice(newVoice[0].name);
    }
    // eslint-disable-next-line
  }, [voiceName]);
  console.log(currEditingInput);
  return (
    <div className={classes.root}>
      <Grid container style={{ display: 'flex', gap: '1em' }} justifyContent="center">
        <Grid
          container
          style={{ display: 'flex', width: 'fit-content' }}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <h2>Select Your Controls</h2>
          {location && location.state && location.state.missingControls && !inputPreference && (
            <div className={classes.warning} style={{ marginBottom: '7px' }}>
              <ErrorOutlineIcon style={{ marginRight: '3px' }} />
              Select your control preference to continue
            </div>
          )}
          <Grid
            container
            style={{ display: 'flex', width: 'fit-content' }}
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ControlOption
                tabIndex={1}
                handleChoice={handleChoice}
                choice={'left'}
                keyboard={<Keyboard color={'red'} up={'W'} down={'S'} left={'A'} right={'D'} />}
              />
              <br></br>
              <span>LEFT HAND</span>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ControlOption
                tabIndex={2}
                handleChoice={handleChoice}
                choice={'right'}
                keyboard={<Keyboard color={'#1E90FF'} up={'I'} down={'K'} left={'J'} right={'L'} />}
              />
              <br></br>
              <span>RIGHT HAND</span>
            </Grid>
          </Grid>
          <p style={{ fontWeight: 'normal', fontSize: '16px' }}>
            Choose which hand you would like to use for the game controls.
          </p>
        </Grid>
        <Grid container style={{ width: '2px', borderRight: '2px solid black' }} />
        <Grid container style={{ display: 'flex', width: 'fit-content' }} direction="column" alignItems="center">
          <h2>Select a Voice</h2>
          {location && location.state && location.state.missingVoice && !tts && (
            <div className={classes.warning}>
              <ErrorOutlineIcon style={{ marginRight: '3px' }} />
              Select your voice preference to continue
            </div>
          )}
          <Grid item style={{ height: '294px', overflow: 'auto' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="voice-radio-buttons-group-label"
                defaultValue={voiceName}
                name="voice-radio-buttons-group"
                onChange={handleVoiceChange}
                value={voiceName}
              >
                {voices.map((voice, index) => {
                  return (
                    <FormControlLabel
                      value={voice.name}
                      control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                      label={voice.name}
                      key={index}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <span
          tabIndex={2}
          className={`${classes.button} ${!inputPreference || !tts ? classes.disabled : ''}`}
          onClick={() => {
            if (inputPreference && tts) {
              history.push(`/`);
            }
          }}
        >
          Continue
        </span>
      </Grid>
    </div>
  );
}
