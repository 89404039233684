import React, { useEffect, useState } from 'react';
import { useSpring, useSprings, animated, SpringValue } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

interface DirectionIndex {
  index: number;
}

interface Canvas2Props {
  setCurrWordIndex: (value: DirectionIndex | ((prevVar: DirectionIndex) => DirectionIndex)) => void;
  move: number;
  livesRemaining: number;
  setTriggerReactionTime: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  timer: JSX.Element;
}
interface DirectionIndexMapType {
  [key: string]: number;
}

interface Distraction {
  x: number;
  y: number;
  opacity: number;
  component: JSX.Element;
}

export default function Canvas2(props: Canvas2Props): JSX.Element {
  const { width, height } = getWindowDimensions();
  const maxWidth = width - 200;
  const maxHeight = height * 0.8 - 200 - 3;
  const heightIncrements = Math.floor(maxHeight / 3);
  const widthIncrements = Math.floor(maxWidth / 3);
  const [coordinates, setCoordinates] = useState({ x: 3, y: 53 }); // 3 as a margin from the edge and 53 because the HUD is 53px in height
  const [distractions, setDistraction] = useState<Array<Distraction>>([]);
  const [styles, api] = useSpring(() => ({
    from: { x: 3, y: 53, opacity: 1 },
  }));

  const randomShapes = useSprings(
    distractions.length,
    distractions.map((item: Distraction) => ({
      from: {
        x: item.x,
        y: item.y,
      },
      opacity: item.opacity,
      margin: '10px 10px 10px 10px',
    })),
  );

  const DirectionIndexMap: DirectionIndexMapType = {
    left: 0,
    right: 1,
    up: 2,
    down: 3,
  };

  const setRandomShapes = () => {
    const amountOfShapes = Math.floor(Math.random() * 5);
    const shapes = [];
    const randomShapes = [
      solid('rabbit'),
      solid('hippo'),
      solid('bat'),
      solid('otter'),
      solid('dog'),
      solid('deer'),
      solid('cow'),
      solid('cat-space'),
      solid('dragon'),
      solid('whale'),
      solid('snake'),
      solid('turtle'),
      solid('sheep'),
      solid('pig'),
      solid('narwhal'),
      solid('monkey'),
      solid('alicorn'),
      solid('squirrel'),
      solid('crab'),
      solid('bird'),
      solid('pegasus'),
      solid('mosquito'),
      solid('duck'),
      solid('squid'),
      solid('elephant'),
      solid('dolphin'),
      solid('spider'),
    ];
    const randomColors = ['#A8A8A8', '#C2C2C2', '#D6D6D6'];
    const row = Math.floor(maxWidth / 100);
    const col = Math.floor(maxHeight / 100);

    const UsedIndexMap = new Map();
    for (let j = 0; j < amountOfShapes; j++) {
      let randomIndex = Math.floor(Math.random() * (row * col - 1));
      while (UsedIndexMap.get(randomIndex)) {
        randomIndex = Math.floor(Math.random() * (row * col - 1));
      }
      UsedIndexMap.set(randomIndex, true);
    }
    for (let i = 0; i < row * col; i++) {
      const randomShapeIndex = Math.floor(Math.random() * randomShapes.length);
      const randomColorIndex = Math.floor(Math.random() * randomColors.length);
      shapes.push({
        x: 0,
        y: 0,
        component: (
          <FontAwesomeIcon
            style={{ color: randomColors[randomColorIndex] }}
            icon={randomShapes[randomShapeIndex]}
            size="5x"
          />
        ),
        opacity: UsedIndexMap.get(i) == true ? 1 : 0,
      });
    }
    setDistraction(shapes);
  };

  const getCursorRow = () => {
    switch (coordinates.y) {
      case 53:
        return 1;
      case 53 + heightIncrements:
        return 2;
      case 53 + heightIncrements * 2:
        return 3;
      default:
        return 0;
    }
  };
  const updateDistraction = () => {
    const amountOfShapes = Math.floor(Math.random() * 5);
    const newDistractions = [];
    const UsedIndexMap = new Map();
    const row = Math.floor(maxWidth / 100);
    const col = Math.floor(maxHeight / 100);
    for (let j = 0; j < amountOfShapes; j++) {
      let randomIndex = Math.floor(Math.random() * (row * col - 1));
      while (UsedIndexMap.get(randomIndex)) {
        randomIndex = Math.floor(Math.random() * (row * col - 1));
      }
      UsedIndexMap.set(randomIndex, true);
    }
    const currCursorRow = getCursorRow();
    for (let i = 0; i < distractions.length; i++) {
      const currDistraction = distractions[i];
      currDistraction.opacity = UsedIndexMap.get(i) == true ? 1 : 0;
      if (Math.floor(i / row) + 1 == currCursorRow) {
        currDistraction.opacity = 0;
      }
      newDistractions.push(currDistraction);
    }
    setDistraction(newDistractions);
  };

  const availableOptions = (): Array<string> => {
    const options = [];
    if (coordinates.x + widthIncrements <= maxWidth + 3) {
      options.push('right');
    }
    if (coordinates.x - widthIncrements >= 3) {
      options.push('left');
    }
    if (coordinates.y + heightIncrements <= maxHeight) {
      options.push('down');
    }
    if (coordinates.y - heightIncrements >= 53) {
      options.push('up');
    }
    return options;
  };

  useEffect(() => {
    if (props.move > 0) {
      const options = availableOptions();
      const randomIndex = Math.floor(Math.random() * options.length);
      props.setTriggerReactionTime(true);
      switch (options[randomIndex]) {
        case 'up':
          setCoordinates({ x: coordinates.x, y: coordinates.y - heightIncrements });
          props.setCurrWordIndex({ index: DirectionIndexMap['up'] });
          break;
        case 'down':
          setCoordinates({ x: coordinates.x, y: coordinates.y + heightIncrements });
          props.setCurrWordIndex({ index: DirectionIndexMap['down'] });
          break;
        case 'left':
          setCoordinates({ x: coordinates.x - widthIncrements, y: coordinates.y });
          props.setCurrWordIndex({ index: DirectionIndexMap['left'] });
          break;
        case 'right':
          setCoordinates({ x: coordinates.x + widthIncrements, y: coordinates.y });
          props.setCurrWordIndex({ index: DirectionIndexMap['right'] });
          break;
        default:
          console.error('no where to go');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.move]);

  useEffect(() => {
    api({
      x: coordinates.x,
      y: coordinates.y,
      opacity: 1,
    });
    updateDistraction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  useEffect(() => {
    setRandomShapes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <animated.div style={{ width: '100%', height: '100%' }}>
        <animated.div
          style={{
            width: 200,
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            ...styles,
          }}
        >
          <FontAwesomeIcon
            style={{ color: props.livesRemaining == 3 ? '#1E90FF' : props.livesRemaining == 2 ? '#f0e130' : 'red' }}
            icon={solid('rectangle-wide')}
            size="5x"
          />
          <span style={{ color: 'black', fontSize: '30px', position: 'absolute' }}>{props.timer}</span>
        </animated.div>
        <div style={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap' }}>
          {randomShapes.map((props: { x: SpringValue<number>; y: SpringValue<number> }, i: number) => {
            // eslint-disable-next-line react/jsx-key
            return <animated.div style={props}>{distractions[i].component}</animated.div>;
          })}
        </div>
      </animated.div>
    </div>
  );
}
