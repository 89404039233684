import React from 'react';
import '../Styles/hud.css';

export interface HUDProps {
  width?: number;
  height?: number;
  position?: string;
  background?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}
export default function HUD(props: HUDProps): JSX.Element {
  return (
    <div
      className={props.position ? props.position : 'top right'}
      style={{
        position: 'fixed',
        background: props.background ? props.background : 'transparent',
        width: props.width ? `${props.width}px` : '400px',
        height: props.height ? `${props.height}px` : '30px',
        marginTop: '20px',
      }}
    >
      {props.children}
    </div>
  );
}
