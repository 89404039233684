export default class TTS {
  speechObj: SpeechSynthesisUtterance;
  voice: string;
  constructor(voice: string) {
    this.speechObj = new SpeechSynthesisUtterance();
    this.voice = voice;
  }

  init = (): void => {
    const defaults = {
      volume: 1,
      rate: 1,
      pitch: 1,
      lang: 'en-US',
    };
    Object.assign(this.speechObj, defaults);

    window.speechSynthesis.onvoiceschanged = () => {
      const voices = window.speechSynthesis.getVoices();
      for (let i = 0; i < voices.length; i++) {
        if (voices[i].name === this.voice) {
          this.speechObj.voice = voices[i];
        }
      }
    };
  };

  speak = (word: string): void => {
    this.speechObj.text = word;
    window.speechSynthesis.speak(this.speechObj);
  };
}
