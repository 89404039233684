import React, { useState, useEffect, useRef } from 'react';
import WordCross from './WordCross';
import Timer from './Timer';
import Words from './Words/Words';
import LeftWords from './Words/LeftWords';
import RightWords from './Words/RightWords';
import HUD from '../../Assets/HUD';
import './Styles/WordCross.css';
import TTSModule from './TTS';
import MinuteTimer from './MinuteTimer';

enum Direction {
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

interface CurrentInput {
  key: string;
}
interface GameOver {
  isOver: boolean;
  finalScore: number;
}
interface GameProps {
  userInput: CurrentInput;
  userEditingInput: CurrentInput;
  setCurrEditingInput: (value: CurrentInput | ((prevVar: CurrentInput) => CurrentInput)) => void;
  backgroundImage?: string;
  TTS: TTSModule;
  setGameOver: (value: GameOver | ((prevVar: GameOver) => GameOver)) => void;
  setEnd: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  v2?: boolean;
}

//each word choice from the array of words
interface WordChoice {
  word: string;
  correction: string;
}

//assign each position in the word cross a direction and word choice from the array of word
interface WordCrossChoice {
  wordChoice: WordChoice;
  direction: Direction;
}

//select on of the word cross choices to be the correct word
interface CorrectWordChoice {
  word: WordCrossChoice;
  correctionLocation?: string;
}

export default function WhackAWord(props: GameProps): JSX.Element {
  const [correctWord, setCorrectWord] = useState<CorrectWordChoice | null>(null);
  const [currWordSet, setCurrWordSet] = useState<Array<WordCrossChoice>>([]);
  const [timedOut, setTimedOut] = useState<number>(0);
  const [correct, setCorrect] = useState<number>(0);
  const [timeLimit] = useState<number>(4000);
  const [startDirectionChoice, setStartDirectionChoice] = useState(false);
  const [directionGuessAttempts, setDirectionGuessAttempts] = useState<number>(0);
  const [triggerCorrectAnimation, setTriggerCorrectAnimation] = useState<boolean>(false);
  const [attemptingLast, setAttemptingLast] = useState<boolean>(false);
  const [noTimeLeft, setNoTimeLeft] = useState<boolean>(false);
  const [, setTriggerWrongAnimation] = useState<boolean>(false);
  const [timerUi, setTimerUi] = useState<JSX.Element>(
    <Timer key={correct + timedOut} restart={true} totalSeconds={timeLimit / 1000} />,
  );
  const [score, setScore] = useState<number>(0);
  const [pause, setPause] = useState<boolean>(false);
  const timer = useRef<number | null>(null);
  const WordChoices: Array<WordChoice> = Words();
  const usedCorrectWord = new Map();
  useEffect(() => {
    const gametime = setTimeout(() => {
      setNoTimeLeft(true);
    }, 120000);
    return () => {
      clearTimeout(gametime);
    };
  }, []);

  const getFourNewWords = () => {
    const usedDirectionIndex = new Map();
    const usedWordIndex = new Map();
    const directions = [Direction.UP, Direction.DOWN, Direction.LEFT, Direction.RIGHT];
    const newWordSet: Array<WordCrossChoice> = [];
    for (let i = 0; i < 4; i++) {
      //pick a random word from the array
      let newWordChoiceIndex = Math.floor(Math.random() * WordChoices.length);
      while (usedWordIndex.has(newWordChoiceIndex)) {
        newWordChoiceIndex = Math.floor(Math.random() * WordChoices.length);
      }
      usedWordIndex.set(newWordChoiceIndex, true);

      //pick a random direction to assign the word
      let newDirectionIndex = Math.floor(Math.random() * directions.length);
      while (usedDirectionIndex.has(newDirectionIndex)) {
        newDirectionIndex = Math.floor(Math.random() * directions.length);
      }
      usedDirectionIndex.set(newDirectionIndex, true);

      //push new random word obj
      newWordSet.push({
        wordChoice: WordChoices[newWordChoiceIndex],
        direction: directions[newDirectionIndex],
      });
    }
    setCurrWordSet(newWordSet);

    const selected = newWordSet[Math.floor(Math.random() * newWordSet.length)];
    let newWordChoiceIndex = Math.floor(Math.random() * WordChoices.length);
    while (usedCorrectWord.has(selected.wordChoice.word) || usedWordIndex.has(newWordChoiceIndex)) {
      newWordChoiceIndex = Math.floor(Math.random() * WordChoices.length);
    }
    usedCorrectWord.set(selected.wordChoice.word, true);
    selected.wordChoice = WordChoices[newWordChoiceIndex];
    const newCorrect = {
      word: selected,
    };

    setCorrectWord({ ...newCorrect });
    setStartDirectionChoice(false);
  };

  const getFourNewWordsV2 = async () => {
    const usedDirectionIndex = new Map();
    const usedWordIndex = new Map();
    const directions = [Direction.UP, Direction.DOWN, Direction.LEFT, Direction.RIGHT];
    const newWordSet: Array<WordCrossChoice> = [];
    const wordPlacement = ['LEFT', 'RIGHT'];
    const wordPlacementIndex = Math.floor(Math.random() * 2);
    const WordChoicesV2 = wordPlacement[wordPlacementIndex] === 'LEFT' ? LeftWords() : RightWords();
    for (let i = 0; i < 4; i++) {
      //pick a random word from the array
      let newWordChoiceIndex = Math.floor(Math.random() * WordChoicesV2.length);
      while (usedWordIndex.has(newWordChoiceIndex)) {
        newWordChoiceIndex = Math.floor(Math.random() * WordChoicesV2.length);
      }
      usedWordIndex.set(newWordChoiceIndex, true);

      //pick a random direction to assign the word
      let newDirectionIndex = Math.floor(Math.random() * directions.length);
      while (usedDirectionIndex.has(newDirectionIndex)) {
        newDirectionIndex = Math.floor(Math.random() * directions.length);
      }
      usedDirectionIndex.set(newDirectionIndex, true);

      //push new random word obj
      newWordSet.push({
        wordChoice: WordChoicesV2[newWordChoiceIndex],
        direction: directions[newDirectionIndex],
      });
    }
    setCurrWordSet(newWordSet);

    const selected = newWordSet[Math.floor(Math.random() * newWordSet.length)];
    let newWordChoiceIndex = Math.floor(Math.random() * WordChoicesV2.length);
    while (usedCorrectWord.has(selected.wordChoice.word) || usedWordIndex.has(newWordChoiceIndex)) {
      newWordChoiceIndex = Math.floor(Math.random() * WordChoicesV2.length);
    }
    usedCorrectWord.set(selected.wordChoice.word, true);
    selected.wordChoice = WordChoicesV2[newWordChoiceIndex];
    const newCorrect = {
      word: selected,
      correctionLocation: wordPlacement[wordPlacementIndex],
    };
    setCorrectWord({ ...newCorrect });
    setStartDirectionChoice(false);
  };

  const getPhrase = () => {
    if (correctWord && correctWord.correctionLocation === 'RIGHT') {
      return correctWord.word.wordChoice.word + ' ' + correctWord.word.wordChoice.correction;
    } else {
      return correctWord?.word.wordChoice.correction + ' ' + correctWord?.word.wordChoice.word;
    }
  };

  useEffect(() => {
    if (correctWord) {
      props.v2 ? props.TTS.speak(getPhrase()) : props.TTS.speak(correctWord.word.wordChoice.word);
      setStartDirectionChoice(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correctWord]);

  useEffect(() => {
    if (noTimeLeft && attemptingLast) {
    } else if (noTimeLeft) {
      props.setEnd(true);
      props.setGameOver({ isOver: true, finalScore: score });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptingLast, noTimeLeft]);

  useEffect(() => {
    if (startDirectionChoice == true) {
      //console.log('timer start')
      if (timer != null) {
        setTimerUi(<Timer key={correct + timedOut} restart={true} totalSeconds={timeLimit / 1000} />);
        timer.current = window.setTimeout(() => {
          setTimedOut(timedOut + 1);
        }, timeLimit);
      }
      return () => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDirectionChoice]);

  useEffect(() => {
    if (pause == false) {
      props.v2 != true ? getFourNewWords() : getFourNewWordsV2();
      setTriggerCorrectAnimation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pause]);

  useEffect(() => {
    if (pause != true) {
      timedOut != 0 ? (props.v2 != true ? getFourNewWords() : getFourNewWordsV2()) : void 0;
      setTriggerCorrectAnimation(false);
      timedOut != 0 ? setTriggerWrongAnimation(true) : void 0;
      timedOut != 0 ? setDirectionGuessAttempts(0) : void 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timedOut]);

  useEffect(() => {
    //getFourNewWords()
    if (pause != true) {
      timer.current ? clearTimeout(timer.current) : void 0;
      setTriggerWrongAnimation(false);
      correct != 0 ? setTriggerCorrectAnimation(true) : void 0;
      correct != 0 ? setPause(true) : void 0;
      correct >= 1 ? setScore(score + (directionGuessAttempts > 0 ? 25 : 50)) : void 0;
      setDirectionGuessAttempts(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  useEffect(() => {
    if (pause != true) {
      //console.log('user entered input and direction not paused')
      if (correctWord && props.userInput.key === correctWord.word.direction) {
        setCorrect(correct + 1);
      } else if (props.userInput.key != '') {
        setDirectionGuessAttempts(directionGuessAttempts + 1);
        if (directionGuessAttempts == 1) {
          setTimedOut(timedOut + 1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userInput]);

  return (
    <div>
      <HUD>
        <div className="hud">
          <div className="score">{timerUi}</div>
          <div className="score">
            <MinuteTimer minutes={2} />
          </div>
          <div className="score">{score}</div>
        </div>
      </HUD>
      <div
        key={timedOut + directionGuessAttempts}
        className={timedOut > 0 || (directionGuessAttempts > 0 && timedOut == 0) ? 'wordcross' : ''}
      >
        <WordCross
          v2={props.v2}
          inputPosition={correctWord?.correctionLocation}
          startDirectionChoice={startDirectionChoice}
          userSelected={props.userInput}
          score={score}
          setScore={setScore}
          setTimerUi={setTimerUi}
          words={currWordSet}
          setPause={setPause}
          TTS={props.TTS}
          setAttemptingLast={setAttemptingLast}
          noTimeLeft={noTimeLeft}
          setCurrEditingInput={props.setCurrEditingInput}
          userInput={props.userEditingInput}
          correctionNeeded={correctWord ? { word: correctWord?.word.wordChoice.correction } : undefined}
          correctOccurred={triggerCorrectAnimation}
          correctDirection={correctWord?.word.direction}
        />
      </div>
    </div>
  );
}
