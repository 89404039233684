import React, { useEffect, useState } from 'react';
import ErrorMessage from './ErrorModal';
import { browserName } from 'react-device-detect';

export default function AudioWarningWrapper(props: { children: React.ReactChild }): JSX.Element {
  const [audioNotAllowed, setAudioNotAllowed] = useState(false);
  const playEffect = (src: string) => {
    const effect = new Audio(src);
    effect.play().catch((err) => {
      console.log('browsername', browserName);
      browserName === 'Safari' ? setAudioNotAllowed(true) : void 0;
      console.log(err);
    });
  };

  useEffect(() => {
    playEffect(
      'data:audio/wav;base64,UklGRjIAAABXQVZFZm10IBIAAAABAAEAQB8AAEAfAAABAAgAAABmYWN0BAAAAAAAAABkYXRhAAAAAA==',
    );
  }, []);

  return (
    <div>
      {props.children}
      {audioNotAllowed ? <ErrorMessage /> : ''}
    </div>
  );
}
