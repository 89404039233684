/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import HUD from '../../Assets/HUD';
import Timer from './Timer';
import Canvas2 from './Canvas2';
import Canvas from './Canvas';
import Lives from '../../Assets/Lives';
import TimeElasped from './MinuteTimer';
import '../../Assets/Styles/index.css';
import useReactionTime from '../../Assets/Hooks/useReactionTime';
import useSound from 'use-sound';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CorrectSound from '../../Assets/media/right_answer.mp3';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WrongSound from '../../Assets/media/wrong_answer.mp3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

enum Direction {
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface GameOver {
  isOver: boolean;
  finalScore: number;
  averageReactionTime?: string;
}

export interface CurrentInput {
  key: string;
}

export interface GameProps {
  userInput: CurrentInput;
  backgroundImage?: string;
  setGameOver: (value: GameOver | ((prevVar: GameOver) => GameOver)) => void;
  v2?: boolean;
  mute: boolean;
}

interface RectPositioning {
  x: number;
  y: number;
}

interface DirectionIndex {
  index: number;
}

const PlayWrong = () => {
  const [playWrong, { stop }] = useSound(WrongSound, { soundEnabled: false });
  return { playWrong, stop };
};

const PlayCorrect = () => {
  const [playCorrect, { stop }] = useSound(CorrectSound, { soundEnabled: false });
  return { playCorrect, stop };
};

export default function Game(props: GameProps): JSX.Element {
  const directions: Array<Direction> = [Direction.LEFT, Direction.RIGHT, Direction.UP, Direction.DOWN];
  const [currWordIndex, setCurrWordIndex] = useState<DirectionIndex>({ index: 0 });
  const [timedOut, setTimedOut] = useState<number>(0);
  const [correct, setCorrect] = useState<number>(0);
  const [timeLimit, setTimeLimit] = useState<number>(2000);
  const [triggerCorrectAnimation, setTriggerCorrectAnimation] = useState<boolean>(false);
  const [triggerWrongAnimation, setTriggerWrongAnimation] = useState<boolean>(false);
  const [currPos, setCurrPos] = useState<RectPositioning>({ x: 1, y: 3 });
  const [forceSoundEnabled, setForceSoundEnabled] = useState<boolean>(!props.mute);
  const CorrectSound = PlayCorrect();
  const WrongSound = PlayWrong();
  const { setTriggerReaction, getAverageTime } = useReactionTime();

  const getNewRandomIndex = () => {
    const movementType = ['lateral', 'vertical'];
    const newIndex = Math.floor(Math.random() * 2);
    if (movementType[newIndex] == 'lateral') {
      let newY = Math.floor(Math.random() * 5);
      while (newY == currPos.y) {
        newY = Math.floor(Math.random() * 5);
      }
      setCurrPos({ x: currPos.x, y: newY });
      newY > currPos.y ? setCurrWordIndex({ index: 1 }) : setCurrWordIndex({ index: 0 });
    } else {
      let newX = Math.floor(Math.random() * 4);
      while (newX == currPos.x) {
        newX = Math.floor(Math.random() * 4);
      }
      setCurrPos({ x: newX, y: currPos.y });
      newX > currPos.x ? setCurrWordIndex({ index: 3 }) : setCurrWordIndex({ index: 2 });
    }
    setTriggerReaction(true);
    //setCurrWordIndex(newIndex)
  };

  useEffect(() => {
    setForceSoundEnabled(!props.mute);
  }, [props.mute]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimedOut(timedOut + 1);
    }, timeLimit);
    return () => {
      clearTimeout(timeout);
    };
  }, [currWordIndex]);

  useEffect(() => {
    if (timedOut >= 4) {
      WrongSound.playWrong({ forceSoundEnabled: forceSoundEnabled });
      props.setGameOver({
        isOver: true,
        finalScore: 100 * correct,
        averageReactionTime: `${getAverageTime()}s`,
      });
    } else if (timedOut > 1) {
      props.v2 ? void 0 : getNewRandomIndex();
      setTriggerCorrectAnimation(false);
      timedOut > 1 ? setTriggerWrongAnimation(true) : void 0;
      WrongSound.playWrong({ forceSoundEnabled: forceSoundEnabled });
    } else if (timedOut == 1) {
      props.v2 ? void 0 : getNewRandomIndex();
    }
  }, [timedOut]);

  useEffect(() => {
    correct != 0 ? (props.v2 ? void 0 : getNewRandomIndex()) : void 0;
    setTriggerWrongAnimation(false);
    correct != 0 ? setTriggerCorrectAnimation(true) : void 0;
    if (correct != 0) {
      CorrectSound.playCorrect({ forceSoundEnabled: forceSoundEnabled });
    }
    if (correct === 30) {
      setTimeLimit(1500);
    } else if (correct === 50) {
      setTimeLimit(1000);
    } else if (correct === 75) {
      setTimeLimit(750);
    } else if (correct === 100) {
      setTimeLimit(500);
    }
  }, [correct]);

  useEffect(() => {
    if (timedOut != 4) {
      if (currWordIndex && props.userInput.key === directions[currWordIndex.index]) {
        setCorrect(correct + 1);
        setTriggerReaction(false);
      } else {
        setTimedOut(timedOut + 1);
        setTriggerReaction(false);
      }
    }
  }, [props.userInput]);

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        background: props.backgroundImage ? `url(${props.backgroundImage})` : 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <HUD width={500}>
        <div className="hud">
          <div className="score">
            <TimeElasped continue={timedOut >= 4 ? false : true} />
          </div>
          <div className="score">{100 * correct}</div>
          <div>
            <Lives totalLives={3} livesRemaining={3 - (timedOut - 1)} />
          </div>
        </div>
      </HUD>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80%',
          width: '100%',
        }}
      >
        {timedOut === 1000 ? (
          <Canvas
            livesRemaining={3 - (timedOut - 1)}
            hint={correct + timedOut <= 5 ? true : false}
            currDirection={directions[currWordIndex.index]}
            currPos={currPos}
          />
        ) : (
          ''
        )}
        <Canvas2
          move={correct + timedOut}
          timer={<Timer key={correct + timedOut} restart={true} totalSeconds={timeLimit / 1000} />}
          setTriggerReactionTime={setTriggerReaction}
          livesRemaining={3 - (timedOut - 1)}
          setCurrWordIndex={setCurrWordIndex}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10%',
          width: '100%',
          marginTop: '10px',
        }}
      >
        <FontAwesomeIcon
          icon={duotone('circle-check')}
          size="3x"
          style={
            {
              '--fa-secondary-color': '#20FA65',
              '--fa-primary-color': 'black',
              '--fa-secondary-opacity': 1,
              '--fa-primary-opacity	': 1,
              display: triggerCorrectAnimation ? '' : 'none',
            } as CSSProperties
          }
        />
        <FontAwesomeIcon
          icon={duotone('circle-xmark')}
          size="3x"
          style={
            {
              '--fa-secondary-color': '#E32C29',
              '--fa-primary-color': 'black',
              '--fa-secondary-opacity': 1,
              '--fa-primary-opacity	': 1,
              display: triggerWrongAnimation ? '' : 'none',
            } as CSSProperties
          }
        />
      </div>
    </div>
  );
}
