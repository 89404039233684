import React, { useState } from 'react';
import SlideShow from '../HowToPlay';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    height: '100%',
  },
  snackbar: {
    height: '40px',
    width: '40vw',
    backgroundColor: 'red',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
  },
}));

interface AlertProps {
  message: string;
  onClose: (event?: React.SyntheticEvent<Element, Event> | undefined, reason?: string | undefined) => void;
}

function Alert(props: AlertProps) {
  const classes = useStyles();
  const [instructions, setInstructions] = useState(false);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {instructions ? (
        <SlideShow
          title={'Changing Your Audio Permissions'}
          setClose={setInstructions}
          slides={[{ image: <img alt={'instruction'} /> }]}
        />
      ) : (
        ''
      )}
      <div
        className={classes.snackbar}
        onClick={() => {
          setInstructions(true);
        }}
      >
        {props.message}
      </div>
    </div>
  );
}

export default function CustomizedSnackbars(): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} onClose={handleClose}>
        <Alert onClose={handleClose} message={'Audio Permission Required, Click Here'} />
      </Snackbar>
    </div>
  );
}
