import React, { useEffect, useContext, ReactChild, useState } from 'react';
import { InputContext } from '../Contexts/InputProvider';
import { useHistory } from 'react-router-dom';
import { TTSContext } from '../Contexts/TTSProvider';

export const LEFT_CONTROL_KEYS = '{"w":"UP","s":"DOWN","a":"LEFT","d":"RIGHT"}';
export const RIGHT_CONTROL_KEYS = '{"i":"UP","k":"DOWN","j":"LEFT","l":"RIGHT"}';
export default function ControlWrapper(props: { children: ReactChild }): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { inputPreference, setInputPreference }: any = useContext(InputContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { voice, setVoice }: any = useContext(TTSContext);
  const history = useHistory();
  useEffect(() => {
    if (inputPreference && voice) {
    } else {
      //try to grab data from localstorage
      let controlPreference = localStorage.getItem('controlPreference');
      let voicePreference = localStorage.getItem('voicePreference');

      if (controlPreference !== LEFT_CONTROL_KEYS && controlPreference !== RIGHT_CONTROL_KEYS) {
        controlPreference = null;
      } else {
        setInputPreference(JSON.parse(controlPreference));
      }

      if (voicePreference) {
        setVoice(voicePreference);
      } else {
        voicePreference = null;
      }

      if (!voicePreference || !controlPreference) {
        history.push({
          pathname: '/controls',
          state: { missingVoice: voicePreference ? false : true, missingControls: controlPreference ? false : true },
        });
      }
    }

    // eslint-disable-next-line
  }, []);
  return <div>{props.children}</div>;
}
