import React from 'react';
import '../Styles/lives.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <--

interface LifeProps {
  broken: boolean;
}
export default function Life(props: LifeProps): JSX.Element {
  return (
    <div style={{ paddingLeft: '5px' }}>
      <FontAwesomeIcon
        style={{ color: '#E32C29' }}
        icon={props.broken ? solid('heart-crack') : solid('heart')}
        size="3x"
      />
    </div>
  );
}
