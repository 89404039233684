import React, { useEffect, useState } from 'react';
import Life from './Life';
import '../Styles/lives.css';
interface LivesProp {
  totalLives: number;
  livesRemaining: number;
}
export default function Lives(props: LivesProp): JSX.Element {
  const [lives, setLives] = useState<Array<JSX.Element>>([]);

  useEffect(() => {
    const arr = [];
    for (let j = 0; j < props.totalLives - props.livesRemaining; j++) {
      arr.push(<Life key={j} broken={true} />);
    }
    for (let i = 0; i < props.livesRemaining; i++) {
      arr.push(<Life key={(i + 1) * 10} broken={false} />);
    }
    setLives(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.livesRemaining]);

  return (
    <div className={'livesContainer'} style={{ display: 'flex', flexDirection: 'row' }}>
      {lives.map((val) => {
        return val;
      })}
    </div>
  );
}
