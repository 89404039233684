import React, { useEffect, useState, useContext } from 'react';
import { InputContext } from '../../Contexts/InputProvider';
import Wandirection from '../../../Trainings/Wandirection';
import WhatsThePoint from '../../../Trainings/WhatsThePoint';
import WhackAWord from '../../../Trainings/WhackAWord';
import useGameOver from '../../Hooks/useGameOver';
import GameOver from '../Screens/GameOver';
import Start from '../Screens/Start';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { MuteContext } from '../../Contexts/MuteProvider';
import { TTSContext } from 'src/Components/Contexts/TTSProvider';

interface InsertProps {
  nameOfGame: string;
}

interface GameObj {
  name: string;
  component: JSX.Element;
}
interface GameMap {
  [key: string]: GameObj;
}

export default function InsertGame(props: InsertProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { currInput, currEditingInput, setCurrEditingInput, setCurrInput }: any = useContext(InputContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { mute }: any = useContext(MuteContext);
  const { gameOver, setGameOver } = useGameOver();
  const { search } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { tts }: any = useContext(TTSContext);
  const parsed = QueryString.parse(search);
  const [userReady, setUserReady] = useState<boolean>(false);
  const [startGame, setStartGame] = useState<boolean>(false);
  const [renderGameOver, setRenderGameOver] = useState<boolean>(false);
  useEffect(() => {
    if (userReady === true) {
      const timeout = setInterval(() => {
        setStartGame(true);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [userReady]);

  useEffect(() => {
    if (gameOver.isOver === true) {
      setCurrEditingInput({ key: '' });
      setCurrInput({ key: '' });
      setRenderGameOver(true);
    }
    // eslint-disable-next-line
  }, [gameOver]);

  const GameMap: GameMap = {
    whatsthepoint: {
      component: <WhatsThePoint mute={mute.turnedOn} userInput={currInput} setGameOver={setGameOver} />,
      name: "What's The Point?",
    },
    wandirection: {
      component: <Wandirection mute={mute.turnedOn} v2={true} userInput={currInput} setGameOver={setGameOver} />,
      name: 'Wandirection',
    },
    whackaword: {
      component: (
        <WhackAWord
          tts={tts}
          v2={false}
          setGameOver={setGameOver}
          userInput={currInput}
          userEditingInput={currEditingInput}
          setCurrEditingInput={setCurrEditingInput}
        />
      ),
      name: 'Whack A Word',
    },
    whackaword2: {
      component: (
        <WhackAWord
          tts={tts}
          v2={true}
          setGameOver={setGameOver}
          userInput={currInput}
          userEditingInput={currEditingInput}
          setCurrEditingInput={setCurrEditingInput}
        />
      ),
      name: 'Whack 2 Words',
    },
  };
  if (gameOver.isOver === true && renderGameOver) {
    return (
      <GameOver
        nameOfGame={props.nameOfGame}
        averageReactionTime={gameOver.averageReactionTime ? gameOver.averageReactionTime : undefined}
        score={gameOver.finalScore}
      />
    );
  } else {
    return (
      <div>
        {startGame ? (
          GameMap[props.nameOfGame].component
        ) : (
          <Start
            startGame={parsed['start'] ? true : false}
            nameOfGame={GameMap[props.nameOfGame].name}
            currInput={currEditingInput}
            setUserReady={setUserReady}
          />
        )}
      </div>
    );
  }
}
